import React from "react"
import { Link, graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Img from "gatsby-image"
import { Container, Row, Col } from "react-awesome-styled-grid"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PortfolioNavigationMenu from "../components/portfolioNav"

class PortfolioPostTemplate extends React.Component {
  render() {
    const post = this.props.data.mdx
    const siteTitle = this.props.data.site.siteMetadata.title
    const { previous, next } = this.props.pageContext

    if (post.frontmatter.tags === "video") {
      return (
        <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
        />       
        <Container>
          <PortfolioNavigationMenu/>
          <h2 style={{fontFamily: `"Open Sans"`, textAlign: `center`}}>{post.frontmatter.title}</h2>
          <Row justify="space-around">
            <Col xs={6} sm={6} md={6} align='justify' style={{display: `flex`, flexDirection: `column`}}>
              <MDXRenderer style={{textAlign: `center`}}>{post.body}</MDXRenderer>
            </Col>
          </Row>
          <hr
            style={{
              marginTop: '3rem',
              marginBottom: '3rem',
            }}
          />
          <Row justify="space-around">
            <Col xs={6} sm={6} md={4} style={{display: `contents`, flexDirection: `column`}}>
             <Bio/>
            </Col>
          </Row>

            <ul
              style={{
                marginTop: `3rem`,
                display: `flex`,
                flexWrap: `wrap`,
                justifyContent: `space-between`,
                listStyle: `none`,
                padding: 0,
              }}
            >
              <li>
                {previous && (
                  <Link to={`portfolio${previous.fields.slug}`} rel="prev">
                    ← {previous.frontmatter.title}
                  </Link>
                )}
              </li>
              <li>
                {next && (
                  <Link to={`portfolio${next.fields.slug}`} rel="next">
                    {next.frontmatter.title} →
                  </Link>
                )}
              </li>
            </ul>
        </Container>
        </Layout>
        
      )
      
    }
    else if (post.frontmatter.tags === "podcast") {
      return (
        <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
        />       
        <Container>
          <PortfolioNavigationMenu/>
          <h2 style={{fontFamily: `"Open Sans"`, textAlign: `center`}}>{post.frontmatter.title}</h2>
          <Row justify="space-around">
            <Col xs={6} sm={6} md={6} align='justify' style={{display: `flex`, flexDirection: `column`}}>
              <MDXRenderer style={{textAlign: `center`}}>{post.body}</MDXRenderer>
            </Col>
          </Row>
          <hr
            style={{
              marginTop: '3rem',
              marginBottom: '3rem',
            }}
          />
          <Row justify="space-around">
            <Col xs={6} sm={6} md={4} style={{display: `contents`, flexDirection: `column`}}>
             <Bio/>
            </Col>
          </Row>

            <ul
              style={{
                marginTop: `3rem`,
                display: `flex`,
                flexWrap: `wrap`,
                justifyContent: `space-between`,
                listStyle: `none`,
                padding: 0,
              }}
            >
              <li>
                {previous && (
                  <Link to={`portfolio${previous.fields.slug}`} rel="prev">
                    ← {previous.frontmatter.title}
                  </Link>
                )}
              </li>
              <li>
                {next && (
                  <Link to={`portfolio${next.fields.slug}`} rel="next">
                    {next.frontmatter.title} →
                  </Link>
                )}
              </li>
            </ul>
        </Container>
        </Layout>
        
      )
      
    }
    else {
    return (
      <Layout location={this.props.location} title={siteTitle}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />       
      <Container>
        <PortfolioNavigationMenu/>
        <h2 style={{fontFamily: `"Open Sans"`, textAlign: `center`}}>{post.frontmatter.title}</h2>
        <Row justify="space-around">
          <Col xs={6} sm={6} md={6} align='justify' style={{display: `flex`, flexDirection: `column`, textAlign: `justify`}}>
          <Img fluid={post.frontmatter.thumbnail.childImageSharp.fluid} />
            <MDXRenderer style={{textAlign: `center`}}>{post.body}</MDXRenderer>
          </Col>
        </Row>
        <hr
          style={{
            marginTop: '3rem',
            marginBottom: '3rem',
          }}
        />
        <Row justify="space-around">
          <Col xs={6} sm={6} md={4} style={{display: `contents`, flexDirection: `column`}}>
           <Bio/>
          </Col>
        </Row>

          <ul
            style={{
              marginTop: `3rem`,
              display: `flex`,
              flexWrap: `wrap`,
              justifyContent: `space-between`,
              listStyle: `none`,
              padding: 0,
            }}
          >
            <li>
              {previous && (
                <Link to={`portfolio${previous.fields.slug}`} rel="prev">
                  ← {previous.frontmatter.title}
                </Link>
              )}
            </li>
            <li>
              {next && (
                <Link to={`portfolio${next.fields.slug}`} rel="next">
                  {next.frontmatter.title} →
                </Link>
              )}
            </li>
          </ul>
      </Container>
      </Layout>
    )}
  }
}

export default PortfolioPostTemplate

export const pageQuery = graphql`
  query PortfolioPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    mdx(fields: { slug: {eq: $slug}}, frontmatter: {collection: {eq: "portfolio-post"}}) {
      id
      excerpt(pruneLength: 160)
      body
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        collection
        tags
        thumbnail{
          childImageSharp {
            fluid(maxWidth: 800, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        } 
      }
    }
  }
`